.contact__hero_card {
  align-items: center;
  background: white;
  border-radius: 2vmin;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  padding: 2vmin;
}
.contact__hero_card * {
  color: black;
}

.contact {
  overflow: hidden;
}

.contact__hero_contact_cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

address{
  font-style: normal;
}

@media (max-width: 768px) {
  .contact__hero_contact_cards {
  }
  .contact__hero_card {
    box-sizing: border-box;
    border-radius: 5vmin;
    margin: 0 auto 5vmin auto;
    padding: 5vmin;
    margin: 1vmin;
    text-align: center;
    width: 45%;
  }
  .contact__hero_card.full-width {
    width: 70%;
  }
  .contact__hero_card .icon {
    font-size: 8vmin;
    margin-bottom: 2vmin;
  }
  /* .events__news_container .events__news_avatar.left {
    width: 20%;
  }
  .events__news_container .events__news_avatar {
    width: 30%;
  } */
}

@media (min-width: 769px) {
  .contact .home__stream_background {
    width: 40%;
  }
  .contact .home__stream_input .text_input {
    border: solid gray 2px;
  }
  .events__news_container {
    padding-top: 10vmin;
  }
  .contact__hero_card a,
  .contact__hero_card address {
    font-size: 1.6vmin;
    line-height: 2.1vmin;
    text-align: center;
  }
  .contact__hero_card .icon {
    font-size: 3vmin;
    margin-bottom: 2vmin;
  }
  .contact__hero_card {
    margin: 1vmin;
    width: 20%;
  }
  /* .events__news_container .events__news_avatar.left {
    width: 13%;
  } */
}



@media (min-width: 1300px) {
  .contact__hero_card {
    margin: 1vmin;
    width: 10%;
  }
}