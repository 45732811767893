.vtuber__hero {
  background: url(../../assets/images/gradient_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  position: relative;
  overflow: hidden;
  padding-bottom: 420px;
}

/* .vtuber__hero h4 {
  margin: 4vmin ;
} */

.vtuber_video_info {
  position: relative;
}

.vtuber__shape,
.vtuber__shape_left {
  position: absolute;
}

.vtuber__news{
  margin-top: 80px;
}
.vtuber__news_container {
  position: relative;
}

.vtuber__news_container h4,
.vtuber__news_container p {
  margin: 0 auto;
  width: 90%;
  max-width: 700px;
  text-align: center;
}
.vtuber__news_container h4 {
  font-size: 3.4vmin;
  margin-bottom: 6vh;
}
.vtuber__news_container p {
  font-size: 2.8vmin;
  width: 50%;
}

.vtuber .events__news_container {
  padding-top: 10vmin;
}

.vtuber .home__stream_background {
  left: -10%;
  width: 110vw;
  top: -30vh;
}

@media(max-width:1300px){
  .vtuber .events__news_container {
    padding-top: 0vmin;
  }
}

@media (max-width: 992px) {
  .vtuber__news_container p {
    width: 70%;
  } 
  .vtuber .events__news_container {
    padding-top: 0vmin;
  }
  .vtuber .home__stream_background {
    left: -10%;
    width: 110vw;
    top: -10vh;
  }
}

@media (max-width: 768px) {
  .vtuber__shape_left {
    left: 0;
    top: -10vh;
    width: 18%;
    z-index: -1;
  }
  .vtuber__news_container {
    padding-bottom: 25vh;
    text-align: center;
  }
  .vtuber__news_container h4, .vtuber__news_container p {
    width: 100%;
  }
  .vtuber__news_container h4 {
    font-size: 5vmin;
    margin-bottom: 3vh;
  }
  .vtuber .home__stream_background {
    top: -10vh;
  }
  .vtuber__shape {
    display: inline;
    bottom: 5vh;
    right: 2%;
    width: 30%;
    z-index: 10;
  }
  /* .vtuber__hero  {
    padding-bottom: 28vh;
  } */
  .vtuber__hero{
    padding-bottom: 270px;
  }
  .vtuber_video_info {
    height: 15vh;
  }
  .vtuber_video_info .home__hero_video {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -25vh;
  }
  .vtuber .home__stream_background {
    left: -10%;
    width: 110vw;
    top: -13vh;
  }
}


@media(max-width:600px){
  .vtuber__news_container p {
    font-size: 4vmin;
    width: 70%;
  }
}

@media (min-width: 769px) {
  
  /* .vtuber .events__news_container .events__news_avatar.left {
    width: 20%;
  } */
 
  .vtuber__shape_left {
    left: 0;
    top: -10vh;
    width: 18%;
  }
  .vtuber__shape {
    display: inline;
    bottom: -22vh;
    right:  2%;
    width: 25%;
    z-index: 10;
  }
  .vtuber__news_container {
    padding-top: 0;
    padding-bottom: 15vh;
  }
  

  /* .vtuber__hero h4 {
    font-size: 4vmin;
  } */
  .vtuber_video_info {
    height: 20vh;
  }
  .vtuber_video_info .home__hero_video {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -30vh;
  }
}

@media (min-width: 1500px) {
  /* .vtuber__hero h4 {
    font-size: 2.5vmin;
  } */
}