.events__hero {
  background: url(../../assets/images/gradient_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  position: relative;
  overflow: hidden;
  padding-bottom: 420px;
}

.events__video_banner {
  background: url(../../assets/images/events/hoppia_screenshot_01.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  height: 40vh;
}

/* .events__hero h4 {
  margin: 4vmin ;
} */

.events__video_info_text h4 {
  font-size: 3.4vmin;
  margin-bottom: 6vh;
  width: 90%;
  max-width: 700px;
}

.vtuber_video_info {
  position: relative;
}

.events__shape,
.events__shape_left {
  position: absolute;
}

.events__news_container {
  position: relative;
}

.events__video_info_text {
  text-align: center;
  margin: 0 auto;
  width: 50%;
}

.events__download {
  background: #EEF1F1;
}

.events__download .platform-item img {
  width: 100%;
}

.events__news_event_form .text_input {
  border: solid 1px gray;
}

.buttons-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.event__card {
  padding: 1vw;
  text-align: center;
  height: 100%;
}

.event__card p {
  font-family: InterBold;
  font-size: 30px;
  margin: 0 0 10px;
}

.event__poster {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  height: 50vh;
  margin-bottom: 4vh;
  position: relative;
}

.event__poster .shadow {
  bottom: 0;
  box-shadow: -1px 2px 36px 9px rgba(0,0,0,0.47);
  position: absolute;
  height: 0;
  left: 10%;
  width: 80%;
  z-index: -1;
}

.buttons-container,
.events__news_event_form .home__stream_input label {
  font-family: InterBold;
}

.buttons-container span {
  margin-left: 10px;
}

.events__news_avatar {
  bottom: -5vw;
  position: absolute;
  z-index: -1;
  width: auto;
  height: 20vw;
}

@media (max-width: 768px) {
  .events__news_title {
    margin-top: 15vmin;
    text-align: center;
  }
  .events__news_title h2 {
    font-size: 8.5vmin;
  }
  /* .events__news_avatar {
    bottom: -10vmin;
    position: absolute;
    width: 20%;
  } */

  .events__news_avatar {
    height: 30vw;
  }

  .events__news_avatar.left {
    left: 10%;
  }
  .events__news_avatar.right {
    right: 10%;
  }
  .events__news_slider {
    padding-top: 15vmin;
    padding-bottom: 50vmin;
    position: relative;
  }
  .events .slider-container {
    margin: 0 auto;
    width: 80%;
  } 
  .events__news_event_container {
    margin-top: 25vmin;
    position: relative;
  }
  .events__news_shape_left {
    left: 0;
    position: absolute;
    bottom: -25%;
    width: 40%;
    z-index: -10;
  }
  .events__news_shape {
    top:  10%;
    position: absolute;
    right: 0;
    width: 40%;
    z-index: -10;
  }

  .events__download {
    padding-top: 15vmin;
    padding-bottom: 15vmin;
  }
  .events__download h2 {
    margin-bottom: 5vmin;
    text-align: center;
  }
  .events__download .platform-container {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .events__download .platform-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 5vh;
    max-width: 45%;
  }
  .events__download .platform-item .btn {
    margin-top: 5vmin;
  }
  .events__video_info_text {
    margin-bottom: 10vh;
    width: 80%;
  }
  
  .events__video_info_text {
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
  .events__shape_left {
    left: 0;
    top: -7vh;
    width: 25%;
    z-index: -1;
  }
  .events__news_container {
    padding-top: 10vh;
    padding-bottom: 25vh;
    text-align: center;
  }
  .events__news_container h4 {
    font-size: 5.5vmin;
    margin-bottom: 3vh;
  }
  .vtuber .home__stream_background {
    top: 20vh;
    transform: scale(2);
  }
  .events__shape {
    display: inline;
    bottom: -5vh;
    left: 30%;
    width: 50%;
    z-index: 10;
  }
  /* .events__hero  {
    padding-bottom: 25vh;
  } */
  .vtuber_video_info {
    height: 15vh;
  }
  .vtuber_video_info .home__hero_video {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -25vh;
  }
}

@media (max-width: 600px) {

  .events__news_avatar {
    height: 35vw;
  }
}

@media (min-width: 769px) {
  .events__video_info_text h2 {
    font-size: 3vmin;
  }
  .events__news_title {
    margin-top: 15vmin;
    text-align: center;
  }
  .events__news_title h2 {
    font-size: 5.5vmin;
  }
  .events__news_title p {
    margin: 0 auto;
    margin-top: 5vmin;
    width: 60%;
  }

  .events__news_avatar.left {
    left: 10%;
  }
  .events__news_avatar.right {
    right: 10%;
  }
  .events__news_slider {
    z-index: 1;
    padding-top: 15vmin;
    padding-bottom: 50vmin;
    position: relative;
  }
  .events .slider-container {
    margin: 0 auto;
    width: 80%;
  } 
  .events__news_event_container {
    margin-top: 10vmin;
    position: relative;
  }
  .events__news_shape_left {
    left: 0;
    position: absolute;
    bottom: 40%;
    width: 20%;
  }
  .events__news_shape {
    top:  0;
    position: absolute;
    right: 0;
    width: 25%;
  }
  .events__news_event_form {
    padding-bottom: 25vmin;
    margin: 0 auto;
    width: 35%;
  }
  .events__download {
    padding-top: 15vmin;
    padding-bottom: 25vmin;
  }
  .events__download h2 {
    margin-bottom: 5vmin;
    text-align: center;
  }
  .events__download .platform-container {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .events__download .platform-item {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 0 1rem;
    max-width: 15%;
  }
  .events__download .platform-item .btn {
    margin-top: 5vmin;
  }
  .events__video_info_text {
    padding-top: 10vmin;
    padding-bottom: 25vmin;
  }
  /* .vtuber .home__stream_background {
    left: -10%;
    width: 110vw;
    top: -25vh;
  } */
  .events__shape_left {
    left: 0;
    top: -10vh;
    width: 15%;
  }
  .events__shape {
    display: inline;
    bottom: -22vh;
    right:  5%;
    width: 25%;
    z-index: 10;
  }
  .events__news_container {
    padding-top: 0;
    padding-bottom: 15vh;
  }
  .events__news_container h4,
  .events__news_container p {
    margin: 0 auto;
    width: 50%;
  }
  .events__news_container h4 {
    font-size: 3vmin;
    margin-bottom: 3vh;
  }

  /* .events__hero h4 {
    font-size: 4vmin;
  } */
  .vtuber_video_info {
    height: 20vh;
  }
  .vtuber_video_info .home__hero_video {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -30vh;
  }
}

@media (min-width: 1500px) {
  .events__news_title h2 {
    font-size: 3.5vmin;
  }
  /* .events__hero h4 {
    font-size: 2.5vmin;
  } */
}