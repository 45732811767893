@font-face {
  font-family: InterBold;
  src: url("./assets/fonts/Inter-Bold.otf") format("opentype");
}
@font-face {
  font-family: InterExtraBold;
  src: url("./assets/fonts/Inter-ExtraBold.otf") format("opentype");
}
@font-face {
  font-family: InterExtraLight;
  src: url("./assets/fonts/Inter-ExtraLight.otf") format("opentype");
}
@font-face {
  font-family: InterLight;
  src: url("./assets/fonts/Inter-Light.otf") format("opentype");
}
@font-face {
  font-family: InterSemiBold;
  src: url("./assets/fonts/Inter-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: InterThin;
  src: url("./assets/fonts/Inter-Thin.otf") format("opentype");
}
@font-face {
  font-family: InterMedium;
  src: url("./assets/fonts/Inter-Medium.otf") format("opentype");
}

.font_bold{ font-family: InterBold; }
.font_extra_bold{ font-family: InterExtraBold; }
.font_extra_light{ font-family: InterExtraLight; }
.font_light{font-family: InterLight;}
.font_semi{ font-family: InterSemiBold; }
.font_thin{font-family: InterThin;}
.font_medium{font-family: InterMedium;}


textarea {
  resize: none;
}

html {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  font-family: InterLight;
  font-size: 16px;
  max-width: 100vw;
  overflow: hidden;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: InterSemiBold;
  margin: 0;
}

.error {
  color: crimson;
}

.btn {
  background: #047ce8;
  border-radius: 2vmin;
  display: inline-block;
  font-family: InterSemiBold;
  color: white;
  cursor: pointer;
  padding: 1vmin 2vmin;
}

.text_input {
  border-radius: 2vmin;
  background: white;
  border: none;
  font-family: InterSemiBold;
  outline: none;
  padding: 1vmin 2vmin;
}

.bottom_space {
  margin-bottom: 4vh;
}

.site__block {
  padding-left: 15%;
  padding-right: 15%;
}

.text-align-center {
  text-align: center;
}

.bg_purple {
  background: #8764e3;
}

.video_item  {
  box-shadow: 7px 0px 18px 0px rgba(0,0,0,0.59);
}
.slick-prev:before, .slick-next:before {
  color: #8764e3 !important;
}

.hero_title{
  font-size: 3.4vmin;
  color: white;
  margin-top: 20px;
}
.hero_image_title{
  width: 35vw;
  max-width: 400px;
}

.home__hero_logo {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 5vh;
  padding-bottom: 10vh;
}
.video_block{
  position: relative;
  z-index: 2;
}
.video_container{
  position: relative;
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
}
.video_responsive {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  box-shadow: 7px 0px 18px 0px rgba(0,0,0,0.59);
}

.video_container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: calc(100% + 1px );
  height: 100%;
}

.video_margin_top_negative{
  margin-top: -440px;
}


@media (max-width: 768px) { 
  .text_input {
    border-radius: 4vmin;
    padding: 2vmin 4vmin;
  }
  .btn {
    border-radius: 4vmin;
    padding: 2vmin 4vmin;
  }
  .site__block {
    padding-left: 5%;
    padding-right: 5%;
  }
  .hidden-mobile{
    display: none;
  }
  .hero_image_title  {
    width: 60%;
  }

  .video_margin_top_negative {
    margin-top: -320px;
  }
}

@media(max-width:600px){
  .hero_title {
    font-size: 5vmin;
  }
}

@media (min-width: 769px) {
  .data_scroll_container {
  }
  .scroll__background {
    min-height: 40vh;
    max-height: 40vh;
    overflow: hidden;
    position: relative;
  }
  
  .parallax__image {
    top: -2vh;
    min-height: 48vh;
    position: absolute;
    left: 0;
    right: 0;
  }
  
  .slick-prev {
    left: -4vw;
  }
  
  .slick-next {
    right: -4vw;
  }
  
  .slick-prev:before, .slick-next:before {
    color: rebeccapurple !important;
    font-size: 2rem !important;
  }
  .site__block {
    padding-left: 10%;
    padding-right: 10%;
  }
  
}

@media (min-width: 1500px) {
  body {
    font-size: 15px;
  } 
  /* .site__block {
    padding-left: 15%;
    padding-right: 15%;
  } */
}
