.home__hero {
  background: url(../../assets/images/gradient_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

/* .home__hero h4 {
  color: white;
  margin-top: 10px;
} */

.text_align_center {
  text-align: center;
}

.home__bottom_shape {
  background: white;
  bottom: -15vh;
  border-radius: 100%;
  height: 20vh;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  transform: scale(1.5);
}

.home__hero_video {
  padding: 20vmin 0 0 0;
  position: relative;
}



.home__hero_video iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.home__hero .home__hero_video_info {
  color: rgb(255, 255, 255, 0.8);
  width: 90%;
}

.home__video_banner {
  background: url(../../assets/images/home/hoppia_banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  height: 40vh;
}

.home__stream_input {
  display: flex;
  flex-direction: column;
}

.home__stream_container {
  position: relative;
  display: flex;
  margin-top: 25vh;
  padding-bottom: 15vh;
  justify-content: space-between;
}

.home__stream_background {
  bottom: 0;
  left: 10%;
  position: absolute;
  right: 0;
  top: -15vh;
  width: 100vw;
  z-index: -1;
}

.lerp-wrap {
}
.lerp-wrap span {
  display: inline-block;
}


.home__stream_input {
  margin-bottom: 2vh;
}
.home__stream_input label {
  margin-bottom: 1vh;
}
.home__stream_title h2 {
  font-size: 6vmin;
}
.home__hero_video_form_title {
  margin-bottom: 1.5vmin;
  font-size: 2.2vmin;
}
@media (max-width: 1300px) {
  .home__stream_title h2 {
    font-size: 5vmin;
  }
}

@media(max-width:992px){
  .home__stream_container {
    padding-bottom: 8vh;
  }
}

@media (max-width: 768px) {
  .home__stream_container {
    display: block;
  }
  .home__stream_title {
    margin-bottom: 3vh;
  }
  .home__stream_title h2 {
    font-size: 6vmin;
    text-align: center;
  }
  
  .home__hero  {
    padding-bottom: 40vmin;
  }
  /* .home__hero_logo img {
    width: 80%;
  } */
  .home__hero_video {
    background: black;
    height: 20vh;
    width: 90vw;
  }
  .site__block_logo {
    width: 70%;
  }
  .home__hero_video_info h4, .home__hero_video_info h4 {
    font-size: 4vmin;
  }
  .home__hero_video_info .site__block_logo {
    margin: 100px 0 30px 0;
  }
  .home__hero_video_form {
    align-items: center;
    display: flex;
    /* flex-direction: column; */
    position: relative;
  }
  .home__hero_video_form .text_input {
    box-sizing: border-box;
    margin: 0vh 15px 0 0;
    width: 100%;
  }
  .home__events {
    padding-bottom: 10vh;
    padding-top: 5vh;
  }
  .home__events .home__hero_video_form {
    align-items: flex-start;
    padding-top: 3vh;
  }
  .home__stream_container {
    padding-bottom: 15vh !important;
    padding-top: 10vh !important;
  }
  .home__stream_form .btn {
    margin: 1vh 0;
  }
  .home__stream_background {
    left: -10%;
    position: absolute;
    right: -10%;
    top: 20%;
    height: 100%;
    max-height: 60vh;
    width: 150vw;
    z-index: -1;
    transform: scale(2);
  }
}
@media(max-width:600px){
  .home__stream_container {
    padding-top: 5vh !important;
  }
  .home__hero_video_info .site__block_logo {
    margin: 50px 0 30px 0;
  }
}

@media (min-width: 769px) {
  /* .home__hero h4 {
    font-size: 3vmin;
  } */

  .site__block_logo {
    margin-bottom: 3vh;
    width: 30vw;
  }
  /* .home__hero_logo img {
    max-width: 300px;
  } */
  .home__hero  {
    padding-bottom: 30vmin;
  }
  /* .home__hero_video_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  } */
  .home__hero_video {
    background: black;
    /* height: 20vh; */
    width: 40vw;
  }
  .home__hero .home__hero_video_info,
  .home__events .home__hero_video_info {
    width: 100%;
  }
  .home__hero_video_form {
    display: flex;
    justify-content: space-between;
  }
  .home__hero_video_form input {
    flex-grow: 1;
  }
  .home__hero_video_form .text_input {
    margin-right: 10px;
  }
  
  .home__hero_video_info {
    box-sizing: border-box;
    padding: 0 0 0 5%;
    width: 40%;
  }
  .home__hero_video_info h4 {
    font-size: 3vmin;
  }
  .home__hero_video_info li {
    margin-bottom: 2.5vh;
  }
  
  .home__events {
    padding: 10vmin 0 25vmin;
  }
  .home__stream {
  }
 
  .home__stream_form {
    width: 40%;
  }
  .home__stream_form .btn {
    margin: 2vh 0;
  }
}
@media (min-width:1000px){
  .home__hero_video_container .video_container{
    width: 60%;
  }
  .home__hero .home__hero_video_info, .home__events .home__hero_video_info {
    box-sizing: border-box;
    padding: 0 5%;
    width: 48%;
  }
  .home__hero_video_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .site__block_logo {
    margin-bottom: 3vh;
    width: 18vw;
  }
  .home__hero  {
    padding-bottom: 25vmin;
  }
}
@media (min-width: 1500px) {
  .home__stream_title h2 {
    font-size: 6vmin;
  }
  .site__block_logo {
    width: 14vw;
  }
  .home__hero_video_info h4 {
    font-size: 2.7vmin;
  }
  /* .home__hero_logo img {
    max-width: 370px;
  } */
  .home__hero .home__hero_video_info, .home__events .home__hero_video_info {
    width: 40%;
  }
}