.about__hero {
  background: url(../../assets/images/gradient_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  position: relative;
  overflow: hidden;
  padding-bottom: 40vmin;
}

.about__hero h1 {
  font-size: 6vmin;
}

.about__hero .logo {
  margin-left: 1vmin;
  width: 30vmin;
}

.home__hero_title {
  align-items: center;
  display: flex;
}

.about__products {
  position: relative;
}

.about__products_tablet {
  left: 0;
  position: absolute;
  right: 0;
}

.card__container {
  background: whitesmoke;
  box-shadow: -2px 1px 15px 5px rgba(0,0,0,0.2);
  padding: 1vmin;
  margin: 1vmin;
}

.about .home__video_banner {
  background: url(../../assets/images/about/hoppia_banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
}

.about__experience {
  padding: 10vh 0;
}

.about__users {
  background: #eef1f6;
}

.newsSlider_item {
  box-sizing: border-box;
  color: black;
  padding: 0 0.5vmin;
}

.newsSlider_item img {
  margin-bottom: 5vmin;
  width: 100%;
}

.about__shape {
  position: absolute;
}

.about__news_container {
  position: relative;
}

@media (max-width: 768px) {
  .about .home__stream_background {
    top: -10vh;
  }
  .about__shape {
    display: inline;
    bottom: -5vh;
    left: 30%;
    width: 50%;
    z-index: 10;
  }
  .about__news_container {
    padding-top: 10vh;
    padding-bottom: 25vh;
  }
  .about__news_container h4 {
    font-size: 5.5vmin;
    margin-bottom: 3vh;
  }
  .about__users {
    padding-top: 25vmin;
    padding-bottom: 15vmin;
    font-size: 6vmin;
    text-align: center;
  }
  .about__users_container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .about__users_container img {
    margin: 3vh 0;
    width: 45%;
  }
  .about__experience {
    display: flex;
    flex-direction: column;
  }
  .about__experience .image_one {
    width: 30%;
  }
  .about__experience h3 {
    margin: 0 auto;
    width: 80%;
  }
  .about__experience .image_two {
    align-self: flex-end;
    margin-top: 5vh;
    width: 40%;
  }
  .about__products {
    margin-bottom: 10vh;
  }
  .about__products_title {
    font-size: 7vmin;
    margin-bottom: 5vh;
  }
  .home__hero_title {
    flex-direction: column;
  }
  .about__products {
    padding-top: 10vh;
  }
  .about__products_tablet {
    margin: 0 auto;
    top: -25vh;
    width: 80vw;
  }
  .card__container {
    border-radius: 2vmin;
    box-shadow: -2px 1px 7px 5px rgba(0,0,0,0.15);
    padding: 5vmin;
    margin-bottom: 4vmin;
  }
  .card__container_image {
    display: block;
    margin: 0 0 5vh 0;
    width: 80%;
  }
  .card__container h4 {
    font-size: 5.5vmin;
    font-family: InterLight;
    margin: 1.5vmin 0;
  }
}

@media (min-width: 769px) {
  .about .newsSlider_item img {
    height: 20vmin;
    width: 15vmin;
  }
  .card__container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .about .home__stream_background {
    left: -10%;
    width: 110vw;
    top: -25vh;
  }
  .about__shape {
    display: inline;
    bottom: -20vh;
    left: 10%;
    width: 15%;
    z-index: 10;
  }
  .about__news_container {
    padding-top: 20vh;
    padding-bottom: 15vh;
  }
  .about__news_container h4 {
    font-size: 3vmin;
    margin-bottom: 3vh;
  }
  .about__users {
    padding-top: 15vmin;
    padding-bottom: 15vmin;
    font-size: 4vmin;
    text-align: center;
  }
  .about__users_container {
    display: flex;
    justify-content: space-between;
    margin-top: 5vh;
  }
  .about__users_container img {
    object-fit: contain;
    width: 14%;
  }
  .about__experience {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 40vh;
    position: relative;
    font-size: 2.2vmin;
  }
  .about__experience .image_one {
    top: 10%;
    position: absolute;
    left: 0;
    width: 10%;
  }
  .about__experience .image_two {
    align-self: flex-end;
    bottom: -30%;
    margin-top: 5vh;
    position: absolute;
    right: 0;
    width: 20%;
  }
  .about__experience h3 {
    margin: 0 auto;
    width: 50%;
  }
  .about__products_title {
    font-size: 4vmin;
    margin-bottom: 5vh;
  }
  .about__products_cards {
    display: flex;
  }
  .about__products {
    padding-top: 25vh;
    padding-bottom: 25vh;
  }
  .about__products_tablet {
    margin: 0 auto;
    top: -35vmin;
    height: 50vmin;
  }
  .card__container_image {
    width: 50%;
  }
  .about__products_cards {
    padding: 0 5%;
  }
  .card__container {
    border-radius: 1vmin;
    box-sizing: border-box;
    padding: 5vmin;
    margin: 2vmin;
    width: 50%;
  }
  .card__container h4 {
    font-size: 3vmin;
    font-family: InterLight;
    margin: 1.5vmin 0;
  }
}

@media (min-width: 1500px) {
  .card__container h4 {
    font-size: 2.4vmin;
  }
  .about__news_container h4 {
    font-size: 2vmin;
    margin-bottom: 3vh;
  }
  .about__users {
    font-size: 2.5vmin;
  }
  .about__products_title {
    font-size: 3vmin;
  }
  .about__hero h1 {
    font-size: 6vmin;
  }
}